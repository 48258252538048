.mr-5{
    margin-right: 5px;
}
.mb-20{
    margin-bottom: 20px;
}
.clearfix {


    &::after {
        display: block;
        clear: both;
        content: "";
    }
}
.img-fullwidth{
    width: 100%; 
}
.div-balance-footer {
    padding-bottom: 105px;
}

.truncate-overflow {
    --max-lines: 3;
    --lh: 24px;
    position: relative;
    height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    line-height: var(--lh);

   /* &:after {
        content: "...";
        display: block;
        position: absolute;
        right: 14px;
        bottom: 0;
        background-color: #fff;
    }*/
}

.news-title-truncate-overflow {
    --max-lines: 3;
    --lh: 26px;
    position: relative;
    height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    line-height: var(--lh);
}

.multi-line {
    white-space: pre-line;
    margin-bottom: 0;
}
.has-error{
    small{
        color: orangered;
        font-size: 12px;
    }
}
.comment-form__input-box {
    select {
        height: 60px;
        border: none;
        border-radius: 10px;
        background-color: #eef0f6;
        font-size: 14px;
        padding-left: 30px;
        padding-right: 30px;
    }

    label {
        font-size: 14px;
        display: block;
        &.desc{
           margin-top: -7.5px;
           font-size: 13px;
        }
    }

    .fa-star-of-life {
        font-size: 12px;
        color: orangered;
    }
}
.required-char {
    font-weight: 900;
    color: orangered;
}
.swal2-title{
    font-size: 18px !important;

}
.swal2-html-container{
    font-size: 16px !important;
}
.thm-btn {
    &.btn-reverse {
        padding-left: 10px;
        padding-right: 50px;

        i {
            margin-left: 0;
            margin-right: 15px;
        }
    }

    &.btn-no-icon {
        padding-left: 10px;
        padding-right: 10px;
    }
}
.my-course {
    .team-details__top-name {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 15px;
    }

    .team-details__top-text-2,.team-details__top-text-3{
        font-size: 16px;
        line-height:2;
        padding-bottom: 10px;
       padding-top:0;
    }
}
.my-badge{
    padding: 7.5px 15px;
}
.badge-primary {
    background-color: #007bff;
}
.badge-default {
    background-color: #6c757d;
}
.badge-success {
    background-color: #28a745;
}
.badge-warning {
    background-color: #ffc107;
}
.badge-danger {
    background-color: #dc3545;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

#testPopup {
    .list-answer {
        padding-left:0;
        li {
            display: block;
            border: 1px solid #e4e5ea;
            padding: 15px 30px;
            margin-bottom: 10px;
            border-radius: 10px;
            cursor: pointer;
            &:last-child{
                margin-bottom:0;
            }
        }
    }
}
#my-select-2 {
    .select2-container .select2-selection--single {
        height: 60px;
        border-radius: 10px;
        font-size: 14px;
        background-color: #eef0f6;
        border:none;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 60px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 60px;
        width: 40px;
    }
}
.main-header__top-social {

    .login-link {
        font-size: 18px;
        color: var(--brote-base);
    }

    .register-link {
    }
}
.comment_rate {
    .fa {
        color: var(--brote-gray);

        &.checked {
            color: var(--brote-primary);
        }
    }
}

.blog-details__left{
    img{
        max-width: 100%;
        margin: 0 auto;
    }
}

.portfolio-details__pagination li span, .portfolio-details__pagination li a {
    font-size: 16px;
    color: var(--brote-base);
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}


.portfolio-details__pagination li span, .portfolio-details__pagination li a, .portfolio-details__pagination li.next i {
    position: relative;
    height: 40px;
    width: 40px;
    background-color: #d9f8ff;
    color: #00c8fa;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 15px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    margin-right: 5px;
    z-index: 1;
}

.portfolio-details__pagination li span:hover, .portfolio-details__pagination li a:hover, .portfolio-details__pagination li.active span, .portfolio-details__pagination li.next:hover span, .portfolio-details__pagination li a.next:hover span, .portfolio-details__pagination li a.next:hover span i, .portfolio-details__pagination li.previous:hover span {
    background-color: var(--brote-base);
    color: #fff;
}

.page-link{
    border: none !important
}

.post-popup__content{
    z-index:1;
}

.popup-signin {
    background: #FFF;
    text-align: center;
    justify-content: center;
    padding: 30px 0;
    border-radius: 12px;
}

.profileImage {
    font-family: Arial, Helvetica, sans-serif;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    font-size: 2rem;
    color: #fff;
    text-align: center;
    justify-content: center;
    line-height: 100px;
    display: inline-block;
}

.forum-author__image{
    justify-content:center;
    text-align:center;
}

.text-line-one {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.blog-details figure.image{
    margin: 0 !important;
}

.blog-details figure.table td {
    border-style: hidden !important;
}

.main-slider-wrap{
    position: relative;
    top: -40px;
    margin-bottom: 40px;
}
#main-slider {
    .slide-item {
        position: relative;

        img.main-img {
            width: 100%;
        }
    } 
}
.main-menu {
    &__user-mobile {
        display: none;
    }
}
.course-item {
    .services-two__text {
        padding:0;
        margin: 15px 0 20px 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        white-space: normal;
    }
}
.we-cleaning__small-img{
    img{
        height: 290px;
        width: 290px;
        object-fit:contain;
    }
}
.sc-slider-wrap {
    .lSGallery{
        li{
            img{
                height: 80px !important;
                width: 100%;
                object-fit:cover;
                object-position: top;
                
            }
        }
    }
}
