@media (max-width: 768px) {
    .main-slider .container {
        padding-bottom: 0;
        padding-top: 0;
    }
    .main-slider-star-1, .main-slider-star-3, .main-slider-star-3{
        display: none;
    }
    .main-slider-wrap{
        margin-bottom:0;
    }
    .we-cleaning {
        padding: 0;

        &__left {
            display: none;
        }

        &__right{
            margin-top: 0;
        }
    }
    .section-title__title {
        font-size: 28px;
        line-height: 34px;
    }
    .main-header__logo > a {
        height: 90px;
        width: 90px;
        img{
            height: 60%;
        }
    }
    .main-header__top-details {
        padding: 0px 0 45px;
    }
    .main-menu {
        &__user-mobile {
            display: flex !important;
            flex-wrap: wrap;

            a {
                flex: 1 0 50%;
                justify-content: center !important;
                line-height: 1.5;

                &.login-link {
                    font-size: 18px !important;
                }
            }
        }
    }
}

@media (max-width: 321px) {
    .main-menu {
        &__user-mobile {     
            a {              
                &.login-link {
                    font-size: 14px !important;
                }
            }
        }
    }
}